import { useState } from "react";
import PricingManagement from "./subscriptionManagment";
import PaymentProcessing from "./paymentProccessing";
import BillingInvoices from "./invoices";

export default function Payament() {
  const [step, setStep] = useState(1);
  return step === 1 ? (
    <PricingManagement
      onNext={() => {
        setStep(2);
      }}
    />
  ) : step === 2 ? (
    <PaymentProcessing
      onNext={() => {
        setStep(3);
      }}
    />
  ) : step === 3 ? (
    <BillingInvoices />
  ) : null;
}
