import React from "react";
import { PauseIcon, PlayIcon, ShieldAlert, SquarePlay } from "lucide-react";
import { Button } from "@nextui-org/button";
import { Card } from "@nextui-org/card";

import { Progress } from "@nextui-org/progress";

import { DotsVerticalIcon } from "@radix-ui/react-icons";
import ActionMenu from "./actionMenu";
import { useToast } from "./ui/use-toast";

import {
  ChannelProvider,
  SourceContent,
  Status,
} from "@/proivder/channelProvider";
import useAppStore from "@/store/store";
import { copyToClipboard } from "@/helpers/helpers";
import { BASE_VIDEO_URL } from "@/constants/urls";
import ExportSource from "./exportSource";

import { Badge } from "@nextui-org/badge";

interface StreamListProps {
  items: SourceContent[];
  channel: SourceContent;
  onPlay?: () => void;
  onChannel: (channel: SourceContent) => void;
  onUpdate: (channel: SourceContent) => void;
  onDelete: (channel: SourceContent) => void;
  onExport: (channel: SourceContent) => void;
  videoPlayer: HTMLVideoElement | null;
}

const StreamList: React.FC<StreamListProps> = ({
  items,
  videoPlayer,
  channel,
  onChannel,
  onUpdate,
  onDelete,
  onPlay,
}) => {
  // const transitions = useTransition(items, {
  //   keys: (item) => item.sourceId,
  //   trail: 400 / items.length,
  //   from: { opacity: 0, scale: 0 },
  //   enter: { opacity: 1, scale: 1 },
  //   leave: { opacity: 0, scale: 0 },
  // });

  const currentSubtitles = useAppStore((state) => state.currentSubtitles);

  const languageProvider = useAppStore((state) => state.languageProvider);

  const userId = useAppStore((state) => state.userId);
  const userRoles = useAppStore((state) => state.userRoles);

  const [channelId, setChannelId] = React.useState<number>(0);

  const [, setLanguages] = React.useState<string[]>([]);

  const { toast } = useToast();

  React.useEffect(() => {
    // console.log(channel.audio[0].lang);
    if (channel) {
      setLanguages(
        languageProvider.getChannelLanguages(
          channel._id,
          currentSubtitles.map((item) => item.label),
          channel.audio[0].lang
        )
      );
    }

    //console.log(items[items.length - 1].name, items[items.length - 1].status, items[items.length - 1].progress);
  }, [languageProvider, channel, items]);

  async function activateChannel(channel: SourceContent, active: boolean) {
    await ChannelProvider.updateChannel({
      ...channel,
      active: active,
    });

    if (toast) {
      toast({
        title: channel.name,
        description: "Channel " + (active ? "Activated" : "Deactivated"),
      });
    }
  }

  function getWaitInQueue(ch: SourceContent) {
    // get queue time with seconds and convert it to hours:minutes:seconds (hh:mm:ss) format 00:00:00
    const queueTime = ch.queue_wait;
    const hours = String(Math.floor(queueTime / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((queueTime % 3600) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(Math.floor(queueTime % 60)).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full py-2">
      {items.map((_channel) => (
        <ActionMenu
          isContextMenu={true}
          isProtected={_channel.is_protected}
          isPublic={_channel.is_public}
          onPublic={async (isPublic) => {
            await ChannelProvider.updateChannel({
              is_public: isPublic,
              _id: _channel._id,
            });

            if (toast) {
              toast({
                title: _channel.name,
                description:
                  "Channel is " + (isPublic ? "Public" : "Private") + " now.",
              });
            }
          }}
          key={_channel._id}
          isPlaying={_channel?._id == channel?._id}
          isActive={
            _channel.active &&
            _channel.status == Status.Recording &&
            _channel.ready &&
            _channel.progress == 100
          }
          isAllow={userId == _channel.owner || userRoles.includes("admin")}
          onActivate={() => activateChannel(_channel, true)}
          onCopy={async () => {
            await copyToClipboard(BASE_VIDEO_URL + _channel.unique_name);

            toast({
              title: "Copied",
              description: "Channel URL copied to clipboard",
              duration: 2000,
            });
          }}
          onDeactivate={() => activateChannel(_channel, false)}
          onDelete={() => onDelete(_channel)}
          onEdit={() => onUpdate(_channel)}
          onPlay={() => onChannel(_channel)}
          onExport={async () => {
            // onExport(_channel)
            //  async () => {
            // const url = await ChannelProvider.openSession(_channel._id, false);
            // copyToClipboard(url!);

            setChannelId(_channel._id!);

            // toast({
            //   title: "Stream URL",
            //   description: "Public Stream URL copied to clipboard",
            //   duration: 2000,
            // });
            //    }
          }}
        >
          <div className="w-full">
            <Badge
              isInvisible={_channel.queue_wait == 0}
              color="warning"
              content={getWaitInQueue(_channel)}
              className=" z-[9999] right-[20px]"
              placement="top-right"
              variant="faded"
              classNames={{
                base: "w-full z-[999] right-0",
              }}
            >
              <Card
                key={_channel._id}
                className={
                  "rounded-lg cursor-pointer w-full overflow-visible " +
                  (channel?._id == _channel._id ? " bg-purple-700" : "") +
                  (_channel.status != Status.Recording ||
                  (_channel.progress > 0 && _channel.progress < 100)
                    ? " bg-opacity-50 cursor-not-allowed"
                    : "")
                }
                isPressable={
                  _channel.active &&
                  _channel.status == Status.Recording &&
                  _channel.ready &&
                  _channel.progress == 100
                }
                style={{ transition: "all ease 0.3s" }}
                onClick={() => {
                  if (_channel?._id == channel?._id && !videoPlayer?.paused) {
                    videoPlayer?.pause();
                  } else {
                    onChannel(_channel);
                    videoPlayer?.play();
                  }

                  if (onPlay) onPlay();
                }}
              >
                <div className="flex gap-2 p-2 w-full justify-between">
                  <div className="flex  gap-2 w-full justify-between items-center">
                    <div className="flex flex-col items-start w-full overflow-hidden justify-between  ">
                      <h3 className="flex-grow  text-sm font-semibold whitespace-nowrap overflow-ellipsis">
                        {_channel.name}
                      </h3>
                      <h3 className="text-[10px] font-semibold flex gap-1 items-center text-slate-300">
                        <SquarePlay size={"16px"} />{" "}
                        {_channel.type.toUpperCase()}
                      </h3>
                      {/* {_channel.active ? <Check color="green" /> : null} */}
                    </div>
                    {/* <div className="flex gap-2">
                    <p className="text-sm">{_channel.sourceType}</p>
                    <p className="text-sm">{_channel.transcode.resolution}</p>
                  </div> */}
                    <div className="flex justify-between gap-2 items-center">
                      <Button
                        className="  w-28"
                        color={_channel.failed_status ? "danger" : "default"}
                        disabled={
                          !_channel.ready ||
                          !_channel.active ||
                          _channel.progress < 100 ||
                          _channel.status != Status.Recording ||
                          (_channel.progress > 0 && _channel.progress < 100)
                        }
                        isDisabled={
                          !_channel.ready ||
                          !_channel.active ||
                          _channel.status != Status.Recording ||
                          (_channel.progress > 0 && _channel.progress < 100)
                        }
                        size="sm"
                        startContent={
                          _channel.status == Status.Queued ||
                          _channel.status == Status.Initializing ||
                          (_channel.progress < 100 && _channel.progress > 0) ? (
                            <div className="relative">
                              <div className="w-4 h-4 bg-white rounded-full animate-ping" />
                              <div className="absolute top-0 left-0 w-4 h-4 bg-white rounded-full" />
                            </div>
                          ) : !_channel?.ready ||
                            !_channel?.active ||
                            _channel?.status != Status.Recording ? (
                            <ShieldAlert />
                          ) : _channel?._id == channel?._id &&
                            !videoPlayer?.paused ? (
                            <PauseIcon />
                          ) : (
                            _channel?.ready &&
                            _channel?.active &&
                            _channel?.status == Status.Recording && <PlayIcon />
                          )
                        }
                        onClick={() => {
                          if (
                            _channel?._id == channel?._id &&
                            !videoPlayer?.paused
                          ) {
                            videoPlayer?.pause();
                          } else {
                            onChannel(_channel);
                          }
                        }}
                      >
                        {_channel?.failed_status == "failed"
                          ? _channel.failed_status
                          : !_channel?.active
                            ? Status[_channel?.status]
                            : _channel?.status != Status.Recording
                              ? Status[_channel?.status]
                              : _channel?._id == channel?._id
                                ? "Playing"
                                : _channel?.progress > 0 &&
                                    _channel?.progress < 100
                                  ? "Preparing"
                                  : "Play"}
                      </Button>

                      <ActionMenu
                        key={_channel._id}
                        isActive={
                          _channel.active &&
                          _channel.status == Status.Recording &&
                          _channel.ready &&
                          _channel.progress == 100 &&
                          (userId == _channel.owner ||
                            userRoles.includes("admin"))
                        }
                        isAllow={
                          userId == _channel.owner ||
                          userRoles.includes("admin")
                        }
                        isPlaying={_channel?._id == channel?._id}
                        isProtected={_channel.is_protected}
                        isPublic={_channel.is_public}
                        onActivate={() => activateChannel(_channel, true)}
                        onCopy={async () => {
                          await copyToClipboard(
                            BASE_VIDEO_URL + _channel.unique_name
                          );

                          toast({
                            title: "Copied",
                            description: "Channel URL copied to clipboard",
                            duration: 2000,
                          });
                        }}
                        onDeactivate={() => activateChannel(_channel, false)}
                        onDelete={() => onDelete(_channel)}
                        onEdit={() => onUpdate(_channel)}
                        onExport={async () => {
                          // onExport(_channel)
                          // const url = await ChannelProvider.openSession(
                          //   _channel._id,
                          //   false
                          // );
                          // copyToClipboard(url!);

                          // toast({
                          //   title: "Stream URL",
                          //   description: "Public Stream URL copied to clipboard",
                          //   duration: 2000,
                          // });

                          setChannelId(_channel._id!);
                        }}
                        onPlay={() => onChannel(_channel)}
                        onPublic={async (isPublic) => {
                          ChannelProvider.updateChannel({
                            is_public: isPublic,
                            _id: _channel._id,
                          });

                          if (toast) {
                            toast({
                              title: _channel.name,
                              description:
                                "Channel is " +
                                (isPublic ? "Public" : "Private" + " now."),
                            });
                          }
                        }}
                      >
                        <Button
                          isIconOnly
                          className=" bg-transparent border-none"
                          size="sm"
                          variant="ghost"
                        >
                          <DotsVerticalIcon />
                        </Button>
                      </ActionMenu>
                    </div>
                  </div>
                </div>

                {_channel && _channel.progress < 100 ? (
                  <Progress
                    maxValue={100}
                    size="sm"
                    value={_channel.progress}
                  />
                ) : null}
              </Card>
            </Badge>
          </div>
        </ActionMenu>
      ))}

      <ExportSource
        channel_id={channelId}
        onClose={() => {
          setChannelId(0);
        }}
      />
    </div>
  );
};

export default StreamList;
