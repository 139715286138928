import { Button } from "@nextui-org/button";
import { Disc2Icon, DownloadIcon } from "lucide-react";
import { Tooltip } from "@nextui-org/tooltip";
import {
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/modal";
import { Progress } from "@nextui-org/progress";

import { NavItem } from "./bottomBar";

import useAppStore from "@/store/store";
import { SourceContent } from "@/proivder/channelProvider";
import { useEffect } from "react";

let ffmpeg: Worker | null = null;

export default function CreateOutput() {
  //const ffmpeg = useRef<Worker | null>(null);

  const editItem = useAppStore((state) => state.editItem);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const videoPlayer = useAppStore((state) => state.videoPlayer);

  const videoBuffer = useAppStore((state) => state.videoBuffer);
  const audioBuffer = useAppStore((state) => state.audioBuffer);

  const subtitleTrack = useAppStore((state) => state.currentSubtitles);

  const channel = useAppStore((state) => state.channel);

  const isMobile = window.innerWidth <= 640;

  const isLoggedin = useAppStore((v) => v.isLoggedin);

  // function cancelEdit() {
  //   onUpdate();
  //   setEditItem(null);
  // }

  function getSubtitleTimeFormat(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours}:${minutes}:${seconds}`;
  }

  function createSubtitleOutputSRTFileFormat(lang: string, videoFile: string) {
    const subtitles = subtitleTrack.find((item) => item.label === lang);

    if (subtitles && subtitles.subtitles && subtitles.subtitles!.length) {
      if (subtitles) {
        const srt = subtitles
          .subtitles!.map((item, index) => {
            const start = getSubtitleTimeFormat(item.start);
            const end = getSubtitleTimeFormat(item.end);

            return `${index + 1}\n${start} --> ${end}\n${item.text}\n\n`;
          })
          .join("");

        const blob = new Blob([srt], { type: "text/srt" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = videoFile + "." + lang + ".srt";
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL;
      }
    }
  }

  async function convertToMp4(_channel: SourceContent) {
    const videoBlob = new Blob(videoBuffer, { type: "video/mp4" });
    const audioBlob = new Blob(audioBuffer, { type: "audio/mp4" });

    const videoBuf = await videoBlob.arrayBuffer();
    const audioBuf = await audioBlob.arrayBuffer();

    ffmpeg?.postMessage({
      type: "run",
      MEMFS: [
        { name: "video.ts", data: videoBuf },
        { name: "audio.ts", data: audioBuf },
      ],

      arguments: [
        "-i",
        "video.ts",
        "-i",
        "audio.ts",

        "-c:v",
        "copy",

        "-c:a",
        "copy",

        "-strict",
        "experimental",
        _channel.name + ".mp4",
      ],
    });
  }

  function loadFFMPEG() {
    if (!ffmpeg) {
      ffmpeg = new Worker("/ffmpeg.js");
    }

    ffmpeg.onmessage = function (e) {
      const msg = e.data;

      switch (msg.type) {
        case "ready":
          ffmpeg?.postMessage({ type: "run", arguments: ["-version"] });
          break;
        case "stdout":
          break;
        case "stderr":
          break;
        case "done":
          const _data = msg.data["MEMFS"];

          if (_data.length > 0) {
            const blob = new Blob([_data[0].data], { type: "video/mp4" });
            const url = URL.createObjectURL(blob);
            // const url = URL.createObjectURL(
            //   new Blob(, { type: "video/mp4" })
            // );
            const a = document.createElement("a");

            a.href = url;
            a.download = _data[0].name;
            subtitleTrack.forEach((item) => {
              createSubtitleOutputSRTFileFormat(
                item.label,
                _data[0].name.split(".")[0]
              );
            });
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
          }

          break;
      }
    };
  }

  function convertSecondsToFormattedTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);

    // add leading zeros
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secStr = sec.toString().padStart(2, "0");

    return `${hoursStr}:${minutesStr}:${secStr}`;
  }

  useEffect(() => {
    if (isOpen) {
      loadFFMPEG();
    }
  }, [isOpen]);

  return (
    <>
      <Tooltip content={editItem ? "Update Source" : "Add Source"}>
        {isMobile ? (
          <NavItem
            icon={<DownloadIcon className="w-5 h-5" />}
            label="Export"
            isActive={false}
            onClick={() => onOpen()}
            disabled={!isLoggedin}
          />
        ) : (
          <Button
            disabled={!isLoggedin}
            isDisabled={!isLoggedin}
            isIconOnly
            color={isLoggedin ? "primary" : "default"}
            size="sm"
            onPress={onOpen}
          >
            <DownloadIcon size={20} />
          </Button>
        )}
      </Tooltip>
      <Modal
        backdrop="transparent"
        className=" rounded-none"
        isOpen={isOpen}
        placement="bottom"
        shouldBlockScroll={false}
        size="4xl"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(_) => (
            <>
              <ModalBody>
                <div className=" min-h-20 flex flex-col justify-center items-center">
                  <div className="flex gap-2 mb-3">
                    <p className="text-sm flex gap-2 items-center">
                      {" "}
                      <Disc2Icon
                        size={16}
                        color="red"
                        className=" animate-pulse"
                      />{" "}
                      <span> Recoding Live: </span>
                    </p>
                    <p className=" text-sm font-semibold">
                      {convertSecondsToFormattedTime(
                        videoPlayer?.buffered.length
                          ? videoPlayer?.buffered?.end(0)
                          : 0
                      )}{" "}
                    </p>
                  </div>
                  <Progress
                    size="sm"
                    isIndeterminate
                    aria-label="Loading..."
                    className="max-w-md"
                    content="Creating Output"
                    title="Creating Output"
                  />
                </div>

                <div className="flex justify-end gap-2">
                  <Button
                    color="primary"
                    size="sm"
                    onPress={() => {
                      convertToMp4(channel!);
                      onClose();
                    }}
                  >
                    Export Recording
                  </Button>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
