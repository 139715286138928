/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from "react";
import Hls, { SubtitleFragProcessedData } from "hls.js";
import { Skeleton } from "@nextui-org/skeleton";
import { Card } from "@nextui-org/card";

import {
  AlertCircleIcon,
  CaptionsIcon,
  HeadphonesIcon,
  LanguagesIcon,
  Minus,
  Plus,
} from "lucide-react";
import { Button, ButtonGroup } from "@nextui-org/button";
import { Tooltip } from "@nextui-org/tooltip";
import { CircularProgress } from "@nextui-org/progress";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
// import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import loader from "../proivder/hlsCustomLoader";

import Captions from "./captions";
// eslint-disable-next-line import/order
import StreamSettings from "./streamSettings";

import { useToast } from "./ui/use-toast";

import {
  ChannelProvider,
  SourceContent,
  Status,
} from "@/proivder/channelProvider";
import useAppStore from "@/store/store";
//import { BASE_VIDEO_URL } from "@/constants/urls";
import { calculateInOutTimes, getSubtitleText } from "@/lib/subtitle";
import { hlsRequestInterceptor } from "@/proivder/hlsInterceptor";
// import { startSession } from "@sentry/react";
// import LanguageSelector from "./languageSelector";
import TranslationLanguageSelector from "./translationLanguageSelector";
//import { BASE_VIDEO_URL } from "@/constants/urls";
import VideoTimeline from "./timeline";

interface VideoPlayerProps {
  channel: SourceContent | null;
  channels: SourceContent[];
  onDelete: (channel: SourceContent) => void;
  onUpdate: (channel: SourceContent) => void;
  onExport: (channel: SourceContent) => void;
  onReady: () => void;
}

export interface Subtitle {
  text: string;
  start: number;
  end: number;
}

export interface SubtitleTrack {
  index: number;
  label: string;
  playlistUr?: string;
  subtitles?: Subtitle[];
  sequence?: number;
}

let interval: any;

interface AudioTrack {
  id: number;
  name: string;
}

export default function VideoPlayer({
  channel,
  channels,
  onReady,
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  let hls = useRef<Hls | null>(null);

  const inFullscreen = useRef(false);

  const retryRef = useRef<number>(0);

  const [loadingAudioTrack, setLoadingAudioTrack] = useState(false);

  const [fontSize, setFontSize] = useState(18);

  const addCaptions = useAppStore((state) => state.addCaptions);
  const setCurrentTime = useAppStore((state) => state.setCurrentTime);
  const setVideoPlayer = useAppStore((state) => state.setVideoPlayer);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [isMuted, setIsMuted] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [, setIsMultipleSubtitles] = useState(false);

  const [subtitleTracks, setSubtitleTracks] = useState<SubtitleTrack[]>([]);
  const _subtitleTracks = useRef<SubtitleTrack[]>([]);

  const [hideCaptions] = useState<number[]>([]);

  const [, setShowControls] = useState(false);

  const controlInterval = useRef<any>();

  const [voiceOver, _] = useState(false);

  const currentSubtitles = useAppStore((state) => state.currentSubtitles);

  const loadId = useAppStore((state) => state.loadId);

  const userId = useAppStore((state) => state.userId);

  const [isVideoOwner, setIsVideoOwner] = useState(false);

  const isLoggedin = useAppStore((state) => state.isLoggedin);

  const setCurrentSubtitleIndex = useAppStore(
    (state) => state.setCurrentSubtitleIndex
  );

  // const [channelTranslations, setChannelTranslations] = useState<string[]>([]);

  const channelTranslations = useAppStore((state) => state.channelTranslations);
  const setChannelTranslations = useAppStore(
    (state) => state.setChannelTranslations
  );

  const [, setTimer] = useState(0);

  const languageProvider = useAppStore((state) => state.languageProvider);

  const updateChannel = useAppStore((state) => state.updateChannel);

  const lastSubtitleText = useRef<string | null>(null);

  const setVideoBuffer = useAppStore((state) => state.setVideoBuffer);
  const setAudioBuffer = useAppStore((state) => state.setAudioBuffer);
  const cleanBuffers = useAppStore((state) => state.cleanBuffers);

  const subtitleFragments = useRef<SubtitleFragProcessedData[]>([]);

  const translateId = useRef<SubtitleTrack | null>(null);

  const [langs, setLangs] = useState<string[]>([]);

  const userRoles = useAppStore((state) => state.userRoles);

  const setCurrentSubtitles = useAppStore((state) => state.setCurrentSubtitles);

  const { toast } = useToast();

  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<number>(0);

  const [subtitleSoundToggle, setSubtitleSoundToggle] = useState(false);

  // function getSubtitleText(lines: string[], num?: number) {
  //   const _num = num !== undefined ? num - 1 : lines.length - 1;

  //   const text = lines[_num];

  //   if (text === undefined) return "";

  //   if (text && text.includes("X-TIMESTAMP-MAP")) {
  //     return "";
  //   }

  //   if (text && text.includes("WEBVTT")) {
  //     return "";
  //   }

  //   if (text.trim().length === 0) {
  //     return getSubtitleText(lines, _num);
  //   }

  //   return text;
  // }

  // function formatTime(time: number) {
  //   const hours = Math.floor(time / 3600);
  //   const minutes = Math.floor((time % 3600) / 60);
  //   const seconds = Math.floor(time % 60);

  //   return `${hours}:${minutes}:${seconds}`;
  // }

  // only keep 6 last subtitle fragments in memory
  // function addSubtitleFragment(data: SubtitleFragProcessedData) {
  //   subtitleFragments.current.push(data);

  //   if (subtitleFragments.current.length > 6) {
  //     subtitleFragments.current.shift();
  //   }
  // }

  function updateVideoTime() {
    if (videoRef.current?.currentTime) {
      setCurrentTime(videoRef.current.currentTime);
      // update component state to rereender

      //  setSubtitleTracks(subtitleTracks);
      setTimer(videoRef.current.currentTime);
    }
  }

  useEffect(() => {
    if (channel) {
      const isOwner = channel.owner === userId;

      setIsVideoOwner(isOwner);
    }
  }, [channel, userId]);

  const removeAllTracks = (video: HTMLVideoElement) => {
    const tracks = video.getElementsByTagName("track");

    while (tracks.length > 0) {
      video.removeChild(tracks[0]);
    }
  };

  async function loadVideo() {
    // if (playerRef.current) {
    //   try {
    //     playerRef.current.destroy();
    //     playerRef.current = null;
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // clear all  video player tracks

    const isMultipleSubtitle = languageProvider.isMultipleSubtiles();

    setIsMultipleSubtitles(isMultipleSubtitle);

    setSubtitleTracks([]);
    _subtitleTracks.current = [];

    if (videoRef.current) {
      videoRef.current.removeEventListener("timeupdate", updateVideoTime);
    }

    if (hls.current) {
      hls.current.destroy();
    }

    setIsLoaded(false);

    setErrorMessages([]);

    if (videoRef.current && channel) {
      videoRef.current.playsInline = true;

      videoRef.current.addEventListener("timeupdate", updateVideoTime);

      setVideoPlayer(videoRef.current);

      var video = videoRef.current;

      const videoSrc = await ChannelProvider.openSession(channel._id);
      // const videoSrc = BASE_VIDEO_URL + channel._id;

      video.addEventListener("loadeddata", () => {
        setIsLoaded(true);
      });

      if (Hls.isSupported()) {
        hls.current = new Hls({
          autoStartLoad: true,
          xhrSetup: hlsRequestInterceptor(hls.current),
          loader: loader,
          startLevel: 0, // Start at the lowest quality level available
          maxBufferLength: 45, // Increase buffer length to 20 seconds
          maxBufferSize: 120 * 1000 * 1000, // Increase buffer size to 120MB
          maxBufferHole: 0.1, // Reduce max buffer hole for tighter buffer control
          fragLoadingMaxRetry: 5, // Increase retry count to 5
          fragLoadingRetryDelay: 2000, // Increase retry delay to 2000 ms
          fragLoadingMaxRetryTimeout: 120000, // Increase max retry timeout to 120 seconds
          abrBandWidthFactor: 0.8, // More conservative ABR settings
          abrBandWidthUpFactor: 0.9, // Less aggressive in increasing bitrate
          lowLatencyMode: false, // Disable low latency mode
          backBufferLength: 45, // Retain 30 seconds of back buffer
          progressive: true, // Enable progressive download

          liveSyncDurationCount: 0,

          //  autoLevelCapping: 1, // Cap to one level above the lowest quality
          startFragPrefetch: true, // Enable prefetching of next fragment
          enableWorker: true, // Use web workers for better performance
          //   recoverMediaError: true, // Enable automatic recovery from media errors
          debug: false, // Disable debug logs in production
        });

        hls.current.on(Hls.Events.BUFFER_APPENDING, (_, data) => {
          if (data.type === "video") {
            onReady();
            // videoBuffer
            //   ? setVideoBuffer([...videoBuffer, data.data])
            //   : setVideoBuffer([data.data]);

            setVideoBuffer(data.data);
          }
          if (data.type === "audio") {
            // console.log(data)
            // audioBuffer
            //   ? setAudioBuffer([...audioBuffer, data.data])
            //   : setAudioBuffer([data.data]);

            setAudioBuffer(data.data);
          }
        });

        hls.current.loadSource(videoSrc!);
        hls.current.attachMedia(video);

        //  hls.current.subtitleDisplay = false;

        if (isMultipleSubtitle) {
          handleMultipleSubtitle(hls.current, video);
        }

        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
          localStorage.setItem("lastVideoId", channel._id.toString());
        });

        hls.current.on(Hls.Events.ERROR, function (_, data) {
          if (data.fatal) {
            // Sentry.captureException(data);
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                setErrorMessages(["A network / server error, Retrying ..."]);

                setTimeout(() => {
                  retryRef.current += 1;
                  hls?.current?.startLoad();
                }, 1000);
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                setErrorMessages([
                  "An error occurred while decoding the video.",
                ]);
                break;
              default:
                setErrorMessages([
                  "An unknown error occurred while loading the video.",
                ]);
                break;
            }
          }
        });

        // check if hls loaded after network error

        hls.current.on(Hls.Events.BUFFER_APPENDED, function () {
          onReady();
          retryRef.current = 0;
          setErrorMessages([]);
        });

        hls.current.on(Hls.Events.LEVEL_LOADED, function () {
          retryRef.current = 0;
          setErrorMessages([]);
        });

        setTimeout(() => {
          setIsMuted(false);
        }, 500);
      }

      // HLS.js is not supported on platforms that do not have Media Source
      // Extensions (MSE) enabled.
      //
      // When the browser has built-in HLS support (check using `canPlayType`),
      // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
      // element through the `src` property. This is using the built-in support
      // of the plain video element, without using HLS.js.
      else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc!;

        videoRef.current.addEventListener("loadedmetadata", () => {
          video.play();
        });
      }
    }
  }

  function handleBeginFullscreen() {
    inFullscreen.current = true;
    videoRef.current!.style.setProperty("--caption-font-size", "30px");
  }

  function handleEndFullscreen() {
    inFullscreen.current = false;
    if (hls.current) {
      hls.current.subtitleTrack = 0;
    }
  }

  function handleDesktopFullscreen() {
    // check if video is in fullscreen mode

    if (document.fullscreenElement) {
      inFullscreen.current = true;

      if (hls.current) {
        hls.current.subtitleDisplay = true;
        //  hls.subtitleTrack = selectedCaption || 0;
      }
    } else {
      inFullscreen.current = false;

      if (hls.current) {
        // hls!.subtitleDisplay = false;
        hls.current.subtitleTrack = 0;
      }
    }
  }

  function handleMultipleSubtitle(hls: Hls, video: HTMLVideoElement) {
    // video.addEventListener("fullscreenchange", () => {
    //   // check if video is in fullscreen mode
    //   if (document.fullscreenElement) {
    //     //videoRef.current?.setAttribute("controls", "true");
    //     inFullscreen.current = true;

    //     if (hls) {
    //       hls!.subtitleDisplay = true;
    //       //  hls.subtitleTrack = selectedCaption || 0;
    //     }

    //     //  alert("fullscreen");
    //     if (selectedCaption != null) {
    //     }
    //   } else {
    //     //videoRef.current?.setAttribute("controls", "true");
    //     inFullscreen.current = false;

    //     if (hls) {
    //       //  hls!.subtitleDisplay = false;
    //     }

    //     // alert("not fullscreen");
    //   }
    // });

    hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (_, data) => {
      const tracks = data.audioTracks.map((track: any, index: number) => ({
        id: index,
        name: track.name || `Track ${index + 1}`,
      }));
      setAudioTracks(tracks);

      initializeAudioTrack();
    });

    hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, (_, data) => {
      setSelectedTrack(data.id);
    });

    hls.subtitleDisplay = false;

    let items: SubtitleTrack[] = [];

    hls.on(Hls.Events.SUBTITLE_TRACK_SWITCH, function (_, data) {
      if (data.id === 0) {
        return;
      }
      // console.log(data);
      // check video player if is full screen then allow to change subtitle track
      if (inFullscreen.current) {
        hls.subtitleTrack = data.id;
      } else {
        hls.subtitleTrack = 0;
        const sub = _subtitleTracks.current.find((v) => v.index === data.id);

        if (sub) {
          translateId.current = sub;
          setCurrentSubtitleIndex(sub.index);
          languageProvider.setChannelLanguage(channel!._id, sub.label, langs);

          translateId.current = sub;

          setLangs(
            languageProvider.getChannelLanguages(
              channel!._id,
              currentSubtitles.map((item) => item.label),
              channel!.audio[0].lang
            )
          );

          fetchAllSubtitleFragments();
        }
      }
    });

    hls.once(Hls.Events.SUBTITLE_TRACK_LOADED, function () {
      if (!hls?.subtitleTracks) return;

      initPts.current = -1;

      setSubtitleTracks([]);

      _subtitleTracks.current = [];

      for (let i = 0; i < hls?.subtitleTracks.length; i++) {
        let track = hls.subtitleTracks[i];

        const languages = localStorage.getItem("selected_languages");

        //  video.textTracks[i].mode = "hidden";

        if (languages) {
          const selectedLanguages = languageProvider.getSelectedLanguages();

          languageProvider.setLanguages(track.lang!);
          if (track.name === "transcript") {
            hls!.subtitleTrack! = i;
            hls.subtitleDisplay = true;
            languageProvider.setSelectedLanguages([
              ...selectedLanguages,
              channel!.audio[0].lang,
            ]);

            setSubtitleTracks((prev) => {
              items = [
                ...prev,
                {
                  index: i,
                  label: channel!.audio[0].lang,
                  playlistUr: track.url,
                },
              ];
              return items;
            });
            _subtitleTracks.current.push({
              index: i,
              label: channel!.audio[0].lang,
              playlistUr: track.url,
            });
          }

          if (track.lang && selectedLanguages.includes(track.lang)) {
            setSubtitleTracks((prev) => {
              items = [
                ...prev.filter((t) => t.index !== i),
                { index: i, label: track.lang! },
              ];

              return items;
            });
            _subtitleTracks.current.push({
              index: i,
              label: track.lang!,
              playlistUr: track.url,
            });

            addCaptions([
              {
                index: i,
                label: track.lang,
                subtitles: [],
                playlistUr: track.url,
              },
            ]);

            const _langs = languageProvider.getChannelLanguages(
              channel!._id,
              subtitleTracks.map((item) => item.label),
              channel!.audio[0].lang
            );

            setLangs(_langs);

            items.push({
              index: i,
              label: track.lang,
              subtitles: [],
              playlistUr: track.url,
            });
          }
        }
      }

      const _langs = languageProvider.getChannelLanguages(
        channel!._id,
        subtitleTracks.map((item) => item.label),
        channel!.audio[0].lang
      );

      setCurrentSubtitles(items);
      translateId.current =
        items.find((item) => item.label === _langs[1]) ?? items[1];

      setCurrentSubtitleIndex(translateId.current?.index ?? 0);

      hls?.on(
        Hls.Events.SUBTITLE_FRAG_PROCESSED,
        async function (_, data: SubtitleFragProcessedData) {
          //  // await handleSubtitleFragment(data, video);

          const cues = video.textTracks[0].cues;

          if (cues) {
            const _tracks: Subtitle[] = [];

            Array.from(cues).forEach((c: TextTrackCue) => {
              const subtitle: Subtitle = {
                text: (c as VTTCue).text,
                start: c.startTime,
                end: c.endTime,
              };

              _tracks.push(subtitle);
              // Process subtitle as needed
            });

            if (subtitleTracks[0] && subtitleTracks[0].subtitles) {
              if (
                _subtitleTracks.current[0]!.subtitles &&
                _tracks.length > _subtitleTracks.current[0]!.subtitles!.length
              ) {
                setSubtitleTracks((prev) => {
                  const tracks = prev.map((t) => {
                    if (t.index === 0) {
                      return {
                        ...t,
                        subtitles: _tracks,
                      };
                    }

                    return t;
                  });

                  _subtitleTracks.current = tracks;

                  addCaptions(tracks);

                  setCurrentSubtitles(tracks);

                  return tracks;
                });
              } else {
                if (subtitleTracks[0])
                  subtitleTracks[0] = {
                    ...subtitleTracks[0],
                    subtitles: _tracks,
                  };

                _subtitleTracks.current[0] = {
                  ..._subtitleTracks.current[0],
                  subtitles: _tracks,
                };

                addCaptions(
                  subtitleTracks[0]
                    ? [subtitleTracks[0]]
                    : [{ index: 0, label: "transcript", subtitles: _tracks }]
                );

                setCurrentSubtitles(_subtitleTracks.current);
              }
            } else {
              if (subtitleTracks[0])
                subtitleTracks[0] = {
                  ...subtitleTracks[0],
                  subtitles: _tracks,
                };

              _subtitleTracks.current[0] = {
                ..._subtitleTracks.current[0],
                subtitles: _tracks,
              };

              addCaptions(
                subtitleTracks[0]
                  ? [subtitleTracks[0]]
                  : [{ index: 0, label: "transcript", subtitles: _tracks }]
              );

              setCurrentSubtitles(_subtitleTracks.current);
            }
          }

          const fragUrl = data.frag.url;

          if (!fragUrl) return;

          if (initPts.current === -1) {
            // get ?pts= value from the url
            initPts.current = parseInt(fragUrl.split("?pts=")[1]);
            // - 5secs in pts
          }
          addSubtitleFragment(data);
          if (translateId.current) {
            await handleSubtitleFragment(data, video!, translateId.current!);
          }
        }
      );
    });
  }

  // only keep 6 last subtitle fragments in memory
  function addSubtitleFragment(data: SubtitleFragProcessedData) {
    subtitleFragments.current.push(data);

    if (subtitleFragments.current.length > 10) {
      subtitleFragments.current.shift();
    }
  }

  // fetch all subtitle fragments and check if time is less video current time
  async function fetchAllSubtitleFragments() {
    if (!subtitleFragments.current) return;

    for (let i = 0; i < subtitleFragments.current.length; i++) {
      const data = subtitleFragments.current[i];

      if (
        data.frag.end > videoRef.current!.currentTime &&
        translateId.current
      ) {
        await handleSubtitleFragment(
          data,
          videoRef.current!,
          translateId.current!
        );
      }
    }
  }

  function initializeAudioTrack() {
    const audioTrack = localStorage.getItem(`audioTrack${channel?._id}`);

    if (audioTrack) {
      // check if audio track is zero
      if (parseInt(audioTrack) === 0) {
        return;
      }
      setSelectedTrack(parseInt(audioTrack));
      handleAudioTrackChange(parseInt(audioTrack));
    }
  }

  const handleAudioTrackChange = async (id: number) => {
    const selectedTrackId = id;

    if (hls.current) {
      hls.current.audioTrack = selectedTrackId;
    }

    // Pause the video and wait for the buffer to load enough (30 seconds)
    const video = videoRef.current;

    setSelectedTrack(selectedTrackId);

    setLoadingAudioTrack(true);

    // // Flush the buffer after switching audio track
    hls.current?.trigger(Hls.Events.BUFFER_FLUSHING, {
      startOffset: 0,
      endOffset: Number.POSITIVE_INFINITY,
      type: "audiovideo",
    });

    if (video) {
      video.pause();

      // Poll the buffer until we reach 30 seconds
      const waitForBuffer = () => {
        const buffered = video.buffered;
        const currentTime = video.currentTime;

        for (let i = 0; i < buffered.length; i++) {
          const bufferStart = buffered.start(i);
          const bufferEnd = buffered.end(i);

          if (bufferStart <= currentTime && bufferEnd - currentTime >= 10) {
            return true; // We've buffered 30 seconds
          }
        }

        return false;
      };

      const waitUntilBuffered = async (timeout: number = 3000) => {
        // save last audio track state in local storage with video id
        localStorage.setItem(
          `audioTrack${channel?._id}`,
          selectedTrackId.toString()
        );

        return new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            if (waitForBuffer()) {
              clearInterval(interval);
              resolve();
            }
          }, 100);

          setTimeout(() => {
            clearInterval(interval);
            resolve(); // Continue after timeout to prevent hanging if buffer is slow
          }, timeout);
        });
      };

      await waitUntilBuffered();

      // Play video after buffering at least 30 seconds
      setLoadingAudioTrack(false);
      video.play();
    }
  };

  async function handleSubtitleFragment(
    data: SubtitleFragProcessedData,
    _video: HTMLVideoElement,
    track?: SubtitleTrack
  ) {
    let level = 0;

    if (track) {
      level = track.index;
    }

    let url = data.frag.url;

    if (track) {
      url = url.replace(/lang=[^&]+/, `lang=${track.label}`);
    }

    // http request for fetching the subtitle
    const response = await fetch(url);

    // Sentry.metrics.distribution("subtitle_fragments_load_time", responseTime, {
    //   tags: { type: "important" },
    //   unit: "millisecond",
    // });

    // if (responseTime > 1000) {
    //   Logger.log("Response time is too long: " + responseTime + "ms");
    // }

    const text = await response.text();

    // check if subtitle response time is too long and missed the video time to show the subtitle text , then log it

    const lines = text.split("\n");

    const sText = getSubtitleText(lines);

    // const _text = getSubtitleText(lines);

    const result = calculateInOutTimes(text, url, initPts.current);

    if (!result) return;

    // const hasPairTranscript =
    //   subtitleTracks[0] &&
    //   subtitleTracks[0].subtitles?.find(
    //     (s) => s.start <= data.frag.start && s.end >= data.frag.end
    //   );

    if (sText.trim().length === 0) {
      return;
    }

    let subtitle: Subtitle = {
      text: sText,
      start: result.inTime,
      end: result.outTime,
    };

    setSubtitleTracks((prev) => {
      const tracks = prev.map((t) => {
        if (t.index === level) {
          return {
            ...t,
            subtitles: t.subtitles ? [...t.subtitles, subtitle] : [subtitle],
          };
        }

        return t;
      });

      _subtitleTracks.current = tracks;

      addCaptions(tracks);

      setCurrentSubtitles(tracks);

      return tracks;
    });
  }

  async function checkAndUpdateModel(lang: string) {
    const modelKey = `${channel?.audio[0].lang}_${lang}`;
    const reverseModelKey = `${lang}_${channel?.audio[0].lang}`;
    const models = await ChannelProvider.getTranslateModels();
    // console.log(res);
    const model = models.find((item) => item.key === modelKey);
    const reverseModel = models.find((item) => item.key === reverseModelKey);

    if (model && !model.load) {
      //  toast
      // ChannelProvider.updateTranslateModels({ ...model, load: true });
      toast({
        title: "Translation Model",
        description: ` ${model.model_name.toLocaleUpperCase()} Model is not loaded yet`,
      });
    }

    if (reverseModel && !reverseModel.load) {
      //ChannelProvider.updateTranslateModels({ ...reverseModel, load: true });
      toast({
        title: "Translation Model",
        description: ` ${reverseModel.model_name.toLocaleUpperCase()} Model is not loaded yet`,
      });
    }

    // if (model && !model.load) {
    //   ChannelProvider.updateTranslateModels({ ...model, load: true });
    // }

    // if (reverseModel && !reverseModel.load) {
    //   ChannelProvider.updateTranslateModels({ ...reverseModel, load: true });
    // }
  }

  useEffect(() => {
    //if (testRef.current) return;

    clearInterval(interval);

    startSetTrackInterval();

    return () => {
      clearInterval(interval);
    };
  }, [subtitleTracks.length, translateId.current]);

  useEffect(() => {
    // set font size
    const size = localStorage.getItem("fontSize");

    if (size) {
      setFontSize(parseInt(size));
    }

    return () => {
      setCurrentSubtitles([]);
      addCaptions([]);
    };
  }, []);

  function showCurrentSubtitle(index: number) {
    if (!subtitleTracks[index] || !subtitleTracks[index].subtitles) return;

    if (!videoRef.current) return;

    let currentTime = hls.current?.media?.currentTime!;

    // sort from lower to higher
    const subtitles = subtitleTracks[index].subtitles.sort(
      (a, b) => a.start - b.start
    );

    let subtitle = subtitles.find(
      (s) => s.start <= currentTime && s.end >= currentTime
    );

    if (subtitle) {
      if (subtitle.text !== lastSubtitleText.current && voiceOver) {
        try {
          const utterance = new SpeechSynthesisUtterance(subtitle.text);

          utterance.lang = subtitleTracks[index].label;
          const textDuration = subtitle.end - subtitle.start;

          // change the rate of the speech base of the subtitle duration and subtitle text length
          utterance.rate = calculateSpeechRate(subtitle.text, textDuration);
          // utterance.pitch = 1.1;

          const voices = speechSynthesis.getVoices();

          // Find and set a male voice
          const maleVoice = voices.find(
            (voice) => voice.name.includes("Male") || voice.name.includes("Man")
            // ||
            // voice.gender === "male"
          );

          if (maleVoice) {
            utterance.voice = maleVoice;
          } else {
            // Fall back to a default voice if a male voice is not found
            utterance.voice = voices[0];
          }

          videoRef.current.volume = 0.3;

          // conver subtitle.
          //  utterance.rate =
          speechSynthesis.cancel();
          window.speechSynthesis.speak(utterance);
        } catch (e) {
          console.log(e);
        }
      }

      lastSubtitleText.current = subtitle.text;

      return subtitle.text;
    }
  }

  // Function to estimate syllable count
  function estimateSyllables(word: string) {
    word = word.toLowerCase();
    if (word.length <= 3) return 1;
    word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, "");
    word = word.replace(/^y/, "");
    const syllableMatch = word.match(/[aeiouy]{1,2}/g);

    return syllableMatch ? syllableMatch.length : 1;
  }

  // Function to estimate total syllables in a text
  function countSyllables(text: string) {
    return text
      .split(/\s+/)
      .reduce((count, word) => count + estimateSyllables(word), 0);
  }

  // Function to calculate the speech rate
  function calculateSpeechRate(
    text: string,
    durationInSeconds: number,
    baseWPM = 175
  ) {
    const syllables = countSyllables(text);
    const syllablesPerSecond = (baseWPM / 60) * 1.4; // Average of 1.4 syllables per word
    const rate = syllables / (durationInSeconds * syllablesPerSecond);

    // Ensure the rate is within the allowed range
    return Math.min(Math.max(rate, 1), 1.3);
  }

  // 1 -> 3 || 3 -> 5 ||  8 -> 10

  function startSetTrackInterval() {
    // fetchSubtitleTracks();
    // //return;
    // interval = setInterval(async () => {
    //   fetchSubtitleTracks(true);
    // }, 10000);
  }

  const initPts = useRef<number>(-1);

  // async function fetchSubtitleTracks(bypass = false) {

  // }
  function changeSubtitleTrack() {
    if (interval) {
      clearInterval(interval);

      subtitleTracks.forEach((_, index) => {
        if (index !== 0) {
          setSubtitleTracks((prev) => {
            const tracks = prev.map((t) => {
              if (t.index === index) {
                return {
                  ...t,
                  subtitles: [],
                };
              }

              return t;
            });

            addCaptions(tracks);

            setCurrentSubtitles(tracks);

            return tracks;
          });
          _subtitleTracks.current = [];
        }
      });
    }

    startSetTrackInterval();
  }

  function handleFontSize(type: "increase" | "decrease") {
    let newFontSize = fontSize;

    if (type === "increase") {
      newFontSize += 2;
    } else {
      newFontSize -= 2;
    }

    if (newFontSize < 8 || newFontSize > 48) {
      return;
    }

    setFontSize(newFontSize);
    localStorage.setItem("fontSize", newFontSize.toString());
  }

  useEffect(() => {
    const cleanupHls = () => {
      if (hls.current) {
        hls.current.stopLoad();
        hls.current.detachMedia();
        hls.current.destroy();
        hls.current = null;
      }
    };

    setChannelTranslations(channel?.translations || []);
    // check if channel is active
    if (!channel) {
      return;
    }
    if (!channel!.active || channel!.status !== Status.Recording) {
      retryRef.current = 5;

      setErrorMessages(["Channel is not Active"]);

      return;
    }

    cleanupHls();

    removeAllTracks(videoRef.current!);

    videoRef.current!.addEventListener(
      "webkitbeginfullscreen",
      handleBeginFullscreen
    );

    videoRef.current!.addEventListener(
      "webkitendfullscreen",
      handleEndFullscreen
    );

    videoRef.current!.addEventListener(
      "fullscreenchange",
      handleDesktopFullscreen
    );

    loadVideo();

    languageProvider.addEventListener(
      "selectedLanguagesChanged",
      changeSubtitleTrack
    );
    languageProvider.addEventListener(
      "multipleSubtitlesChanged",
      changeSubtitleTrack
    );

    return () => {
      removeAllTracks(videoRef.current!);
      setSubtitleTracks([]);
      _subtitleTracks.current = [];
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", updateVideoTime);
      }

      videoRef.current!.removeEventListener(
        "webkitbeginfullscreen",
        handleBeginFullscreen
      );
      videoRef.current!.removeEventListener(
        "webkitendfullscreen",
        handleEndFullscreen
      );
      videoRef.current!.removeEventListener(
        "fullscreenchange",
        handleDesktopFullscreen
      );

      //  playerRef.current?.stop();
      // playerRef.current?.destroy();

      cleanupHls();

      removeAllTracks(videoRef.current!);

      languageProvider.removeEventListener(
        "selectedLanguagesChanged",
        changeSubtitleTrack
      );
      languageProvider.removeEventListener(
        "multipleSubtitlesChanged",
        changeSubtitleTrack
      );

      //  clearInterval(interval);

      cleanBuffers();
    };
  }, [channel]);

  useEffect(() => {
    loadVideo();
  }, [loadId]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    if (channels) {
      const _channel = channels.find((c) => c._id === channel?._id);

      if (!_channel?.active) {
        hls.current?.destroy();
        setSubtitleTracks([]);
        _subtitleTracks.current = [];

        if (videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", updateVideoTime);
          // clearInterval(interval);
        }

        retryRef.current = 5;

        setErrorMessages(["Channel is not active"]);
      }

      if (_channel) {
      } else {
        hls.current?.destroy();
        setSubtitleTracks([]);

        if (videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", updateVideoTime);
          // clearInterval(interval);
        }

        retryRef.current = 5;

        setErrorMessages(["Channel has been deleted or deactivated"]);
      }
    }
  }, [channels, channel]);

  return (
    <div className=" w-full h-full flex flex-col gap-1 relative">
      <div className=" absolute right-10 top-0 z-10 flex items-center gap-4">
        <Captions />
        <VideoTimeline />
        <StreamSettings channel={channel!} />
      </div>

      <div className=" absolute left-16 top-1 z-10 flex items-center gap-4 bg-slate-500 bg-opacity-30 px-3 rounded-md">
        <h2 className=" text-lg"> {channel?.name}</h2>
      </div>

      {errorMessages && errorMessages.length > 0 && (
        <div
          className=" absolute max-md:w-full p-10 left-[50%] text-center z-[9] "
          style={{ transform: "translateX(-50%)", top: "calc(50% - 100px)" }}
        >
          {retryRef.current > 4 ? (
            <div className=" flex gap-2 items-center w-full">
              {" "}
              <AlertCircleIcon className=" animate-pulse" size={"32px"} />{" "}
              <h1 className=" font-semibold text-lg">{errorMessages}</h1>{" "}
            </div>
          ) : (
            <div className="w-full justify-center flex">
              <CircularProgress aria-label="Loading..." />
            </div>
          )}
        </div>
      )}

      <div
        className={"relative " + (isLoaded ? " aspect-video " : "")}
        onMouseEnter={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
        onMouseLeave={() => setShowControls(false)}
        onMouseMove={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
        onTouchStart={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
      >
        {/* <div
          className={
            "video-container " + (inFullscreen.current ? "fullscreen" : "")
          }
          id="videoContainer"
          ref={videoContainerRef}
        > */}
        <video
          ref={videoRef}
          key={channel?._id}
          controls
          className="w-full h-full relative"
          muted={isMuted}
          style={{ display: isLoaded ? "block" : "none" }}
        />
        {/* <div className="fullscreen-btn">
            <Button
              variant="shadow"
              size="sm"
              isIconOnly
              onClick={() => {
                if(videoContainerRef.current) {
                  if (!document.fullscreenElement) {
                    // Request fullscreen
                    if (videoContainerRef.current!.requestFullscreen!) {
                      videoContainerRef.current.requestFullscreen();
                    } 
                    
                    else if ((videoContainerRef.current as any).webkitRequestFullscreen) { // Safari
                      (videoContainerRef.current as any).webkitRequestFullscreen();
                    } 
                   // else if (videoContainer.msRequestFullscreen) { // IE/Edge
                    //   videoContainer.msRequestFullscreen();
                    // }
                    // fullscreenBtn.textContent = 'Exit Fullscreen';
                  } else {
                    // Exit fullscreen
                    if (document.exitFullscreen) {
                      document.exitFullscreen();
                    } 
                    
                    // else if (document.webkitExitFullscreen) { // Safari
                    //   document.webkitExitFullscreen();
                    // } else if (document.msExitFullscreen) { // IE/Edge
                    //   document.msExitFullscreen();
                    // }
                    // fullscreenBtn.textContent = 'Enter Fullscreen';
                  }
                }
                inFullscreen.current = !inFullscreen.current;
              }}
              className="fullscreen-btn"
              id="fullscreenBtn"
            >
              <Fullscreen />
            </Button>
          </div> */}
        {/* </div> */}

        {/* {showCurrentSubtitle(0) && showCurrentSubtitle(0)!.length > 0 && (
          <div
            className={
              "caption left-[50%]  absolute bg-gradient-to-r from-cyan-500 to-blue-500 text-white text-center p-2 rounded bg-opacity-50" +
              (!showControls && !videoRef.current?.paused
                ? " bottom-0"
                : " bottom-[25%] md:bottom-[10%]")
            }
            style={{
              width: "calc(100%)",
              transform: "translateX(-50%)",
            }}
          >
            <p> {showCurrentSubtitle(0)} </p>
          </div>
        )} */}
      </div>

      {!isLoaded && (
        <Card className=" aspect-video space-y-5 p-2" radius="lg">
          <Skeleton className="rounded-lg">
            {channel && (
              <div className="aspect-video">
                <div className="h-full bg-gray-300 rounded-lg" />
              </div>
            )}
          </Skeleton>
        </Card>
      )}

      <div className="flex gap-2   overflow-scroll scrollbar-hide">
        <ButtonGroup>
          <Button
            isIconOnly
            size="sm"
            color={subtitleSoundToggle ? "default" : "primary"}
            variant="solid"
            className=" h-7"
            onClick={() => setSubtitleSoundToggle(false)}
          >
            <CaptionsIcon size={"18px"} />
          </Button>
          <Button
            isIconOnly
            size="sm"
            variant="solid"
            color={!subtitleSoundToggle ? "default" : "primary"}
            className=" h-7"
            onClick={() => setSubtitleSoundToggle(true)}
          >
            <HeadphonesIcon size={"18px"} />
          </Button>
        </ButtonGroup>
        {!subtitleSoundToggle ? (
          <div className="flex gap-2 overflow-scroll scrollbar-hide">
            <ButtonGroup
              color="default"
              size="sm"
              variant="flat"
              className=" rounded-none h-7"
            >
              <Button
                isIconOnly
                size="sm"
                variant="ghost"
                className=" h-7"
                onClick={() => handleFontSize("increase")}
              >
                {" "}
                <Plus size={"18px"} />
              </Button>
              <Button
                onClick={() => handleFontSize("decrease")}
                variant="flat"
                isIconOnly
                size="sm"
                className=" h-7"
              >
                {" "}
                <Minus size={"18px"} />
              </Button>
            </ButtonGroup>

            {(userRoles.includes("admin") || (isLoggedin && isVideoOwner)) && (
              <div className="w-28 max-lg:hidden">
                <TranslationLanguageSelector
                  selectedLanguages={channelTranslations}
                  mobile={false}
                  isAllow={
                    userRoles.includes("admin") ||
                    (isLoggedin && channel?.owner === userId)
                  }
                  onSelect={async (v) => {
                    let translates = Array.from(v)
                      .filter((t) => t)
                      .map((t) => t.toString());

                    if (
                      !userRoles.includes("admin") &&
                      !userRoles.includes("paid_user") &&
                      translates.length > 1
                    ) {
                      translates = [translates[translates.length - 1]];
                    }

                    setChannelTranslations(translates);

                    await updateChannel({
                      _id: channel!._id,
                      //  name: channel!.name,
                      //  url: channel!.url,
                      translations: translates,
                    });

                    await checkAndUpdateModel(
                      translates[translates.length - 1]
                    );

                    toast({
                      title: "Translations",
                      description: "Translations has been updated",
                    });

                    loadVideo();
                  }}
                />
              </div>
            )}
            {currentSubtitles
              .filter((item) => item.label !== channel?.audio[0].lang)
              .map((sub) => (
                <Tooltip key={sub.index} content="Change Language">
                  <Button
                    className=" min-w-20 h-7"
                    color={
                      sub.label === (langs.length > 1 ? langs[1] : "")
                        ? "primary"
                        : "default"
                    }
                    disabled={!channel?.ready}
                    size="sm"
                    startContent={<LanguagesIcon size={16} />}
                    onClick={() => {
                      if (
                        translateId.current &&
                        translateId.current.label == sub.label &&
                        langs.includes(sub.label)
                      ) {
                        translateId.current = null;

                        setLangs([langs[0]]);

                        return;
                      }

                      languageProvider.setChannelLanguage(
                        channel!._id,
                        sub.label,
                        langs
                      );

                      translateId.current = sub;
                      setCurrentSubtitleIndex(translateId.current?.index ?? 0);

                      setLangs(
                        languageProvider.getChannelLanguages(
                          channel!._id,
                          currentSubtitles.map((item) => item.label),
                          channel!.audio[0].lang
                        )
                      );

                      fetchAllSubtitleFragments();
                    }}
                  >
                    {sub.label.toUpperCase()}
                  </Button>
                </Tooltip>
              ))}
          </div>
        ) : (
          audioTracks.length > 0 && (
            <div className="flex gap-2 ">
              {audioTracks.map((track) => (
                <Button
                  key={track.id}
                  startContent={<HeadphonesIcon size={16} />}
                  color={selectedTrack === track.id ? "primary" : "default"}
                  size="sm"
                  className=" min-w-20 h-7"
                  isLoading={loadingAudioTrack && selectedTrack === track.id}
                  onClick={() => {
                    handleAudioTrackChange(track.id);
                  }}
                >
                  {track.name}
                </Button>
              ))}
            </div>
          )
        )}
      </div>

      {subtitleTracks.length > 0 &&
        subtitleTracks.map(
          (track, index) =>
            langs.includes(track.label) &&
            index != 0 && (
              <Card
                key={track.index}
                className={
                  " relative p-1 rounded-md bg-opacity-50  flex flex-col justify-center bg-primary-400  " +
                  (hideCaptions.includes(track.index) ? " h-8" : " min-h-11") +
                  (subtitleTracks[1] &&
                  subtitleTracks[1].subtitles &&
                  subtitleTracks[1].subtitles.length > 0
                    ? " mim-h-11"
                    : " min-h-7")
                }
                style={{
                  transition: "all 0.4s ease",
                }}
              >
                <div className="flex gap-2 absolute top-0 right-2 ">
                  {/* {voiceOver ? (
                    <Volume2 onClick={() => setVoiceOver(false)} />
                  ) : (
                    <VolumeX onClick={() => setVoiceOver(true)} />
                  )} */}

                  {/* {channel && (
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          className=" bg-transparent border-none"
                          size="sm"
                          variant="ghost"
                        >
                          {" "}
                          <DotsHorizontalIcon />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        disallowEmptySelection
                        aria-label="subtitles"
                        selectedKeys={langs.length > 1 ? [langs[1]] : []}
                        selectionMode="single"
                        variant="flat"
                        onSelectionChange={(v) => {
                          languageProvider.setChannelLanguage(
                            channel._id,
                            v.currentKey!,
                            langs
                          );

                          setLangs(
                            languageProvider.getChannelLanguages(
                              channel._id,
                              currentSubtitles.map((item) => item.label),
                              channel.audio[0].lang
                            )
                          );
                        }}
                      >
                        {channel &&
                          channel.translations.map((sub) => (
                            <DropdownItem key={sub} value={sub}>
                              {sub ? sub.toUpperCase() : ""}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                  )} */}
                  {/* <Tooltip content="Collapse caption box">
                    <Button
                      isIconOnly
                      className=" bg-opacity-50"
                      size="sm"
                      onClick={() => toggleHideCaption(track.index)}
                    >
                      {!hideCaptions.includes(track.index) ? (
                        <EyeOff size={12} />
                      ) : (
                        <EyeIcon size={12} />
                      )}
                    </Button>
                  </Tooltip>

                  <Tooltip content="Copy and download as a SRT">
                    <Button
                      isIconOnly
                      className=" bg-opacity-50"
                      size="sm"
                      onClick={() =>
                        copyAllCaptionsAsSubtitleSrtFormatInClipboard(
                          track.index
                        )
                      }
                    >
                      <Download size={12} />
                    </Button>
                  </Tooltip>
                  {isMultipleSubtitles && (
                    <Tooltip content="Add subtitle overlaid on the video">
                      <Button
                        isIconOnly
                        className=" bg-opacity-50"
                        size="sm"
                        onClick={() => setSelectedCaption(track.index)}
                      >
                        <CaptionsIcon size={12} />
                      </Button>
                    </Tooltip>
                  )} */}
                </div>
                {subtitleTracks[translateId.current?.index ?? 0] &&
                subtitleTracks[translateId.current?.index ?? 0].subtitles &&
                subtitleTracks[translateId.current?.index ?? 0].subtitles!
                  .length > 0 ? (
                  <div
                    style={{ fontSize: fontSize }}
                    className={
                      " " + (track.label === "ar" ? " text-right" : "")
                    }
                  >
                    <span>{showCurrentSubtitle(track.index)}</span>
                  </div>
                ) : (
                  <Skeleton className="h-6 bg-primary-400 rounded-sm" />
                )}
              </Card>
            )
        )}

      {channel && (
        <ScrollShadow orientation="horizontal" className="w-full">
          <div className="flex gap-4 p-2">
            {/* <Tooltip content="Edit Channel">
              <Button
                className="min-w-20"
                color="warning"
                size="sm"
                startContent={<EditIcon size={16} />}
                onClick={() => onUpdate(channel!)}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip content="Delete Channel">
              <Button
                className=" min-w-20"
                color="danger"
                size="sm"
                startContent={<Trash2Icon size={16} />}
                onClick={() => onDelete(channel!)}
              >
                Delete
              </Button>
            </Tooltip> */}
          </div>
        </ScrollShadow>
      )}
    </div>
  );
}
