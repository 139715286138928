import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import { Edit, Plus, PlusIcon, PlusSquare, Upload } from "lucide-react";
import { useEffect, useState } from "react";
import { Kbd } from "@nextui-org/kbd";
import { Tooltip } from "@nextui-org/tooltip";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";

import { useToast } from "./ui/use-toast";

import useAppStore from "@/store/store";
import { Channel, SourceContent } from "@/proivder/channelProvider";
// import { AuthProvider } from "@/proivder/authProvider";
import { NavItem } from "./bottomBar";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownTrigger,
// } from "@nextui-org/dropdown";
import LanguageSelector from "./languageSelector";
import { Tab, Tabs } from "@nextui-org/tabs";

interface CreateStoreProps {
  onCreated: () => void;
  onUpdate: () => void;
  editItem: Channel | null;
}

export default function CreateStore({ onCreated, onUpdate }: CreateStoreProps) {
  const createChannel = useAppStore((state) => state.createChannel);
  const updateChannel = useAppStore((state) => state.updateChannel);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //const authProvider = AuthProvider.getInstance();

  const editItem = useAppStore((state) => state.editItem);

  const setEditItem = useAppStore((state) => state.setEditItem);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  //const languages = useAppStore((v) => v.languages);

  const { toast } = useToast();

  const [sources, setSources] = useState<Partial<SourceContent>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadFileName, setUploadFileName] = useState<string>("");
  const [_uploadFileLang, setUploadFileLang] = useState<string>("");

  const setLoginRequired = useAppStore((v) => v.setLoginRequired);

  const isMobile = window.innerWidth <= 640;

  function checkLoggedIn() {
    const isLoggedIn = localStorage.getItem("token");
    setIsLoggedIn(!!isLoggedIn);

    if (!isLoggedIn && isOpen) {
      setLoginRequired(true);
      onClose();
    }
  }

  useEffect(() => {
    checkLoggedIn();
  }, [isOpen]);

  async function handleCreateChannel(source: Partial<SourceContent>) {
    try {
      // check name and url
      // check url is valid
      if (!source.name || source.name.length < 2) {
        throw new Error("Source name: It must be at least 2 characters long.");
      }
      if (!source.url || source.url.length < 5) {
        throw new Error("Source URL: It must be at least 5 characters long.");
      }

      // check source url is valid
      const url = new URL(source.url);
      if (!url.protocol || !url.host) {
        throw new Error("Source URL: Invalid source URL");
      }

      await createChannel({
        name: source.name,
        url: source.url,
        active: true,
        language: source.language || "en",
      });
    } catch (error) {
      // setSources([]);
      throw error;
    }
  }

  async function handleUpdateChannel(source: Partial<SourceContent>) {
    try {
      // check name and url
      // check url is valid
      if (!source.name || source.name.length < 2) {
        throw new Error(
          "Invalid source name. It must be at least 2 characters long."
        );
      }
      if (!source.url || source.url.length < 5) {
        throw new Error(
          "Invalid source URL. It must be at least 5 characters long."
        );
      }

      await updateChannel({
        ...editItem!,
        name: source.name,
      });

      onClose();
    } catch (error) {
      throw error;
    }
  }

  function addNewSource() {
    setSources([
      ...sources,
      {
        url: "",
        name: "",
      },
    ]);
  }

  async function createSources() {
    try {
      setIsLoading(true);
      for (let src of sources) {
        await handleCreateChannel(src);
      }

      onCreated();
      setIsLoading(false);
      setSources([
        {
          url: "",
          name: "",
        },
      ]);

      toast({
        title: "Success",
        description:
          "Source created successfully, Stream will be available soon",
        duration: 5000,
      });

      onClose();
    } catch (e: any) {
      toast({
        title: "Error",
        description: e.message,
        duration: 5000,
      });

      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (editItem) {
      setSources([
        {
          url:
            editItem &&
            editItem.transcode.profiles &&
            editItem.transcode.profiles.length
              ? editItem.transcode.profiles[0].url
              : "",
          name: editItem ? editItem.name : "",
        },
      ]);

      onOpen();
    }
  }, [editItem]);

  useEffect(() => {
    if (!isOpen) {
      setEditItem(null);
      setSources([
        {
          url: "",
          name: "",
        },
      ]);
    }
  }, [isOpen]);

  // function cancelEdit() {
  //   onUpdate();
  //   setEditItem(null);
  // }

  async function updateSrouce() {
    try {
      setIsLoading(true);
      for (let src of sources) {
        await handleUpdateChannel(src);
      }
      setIsLoading(false);
      onUpdate();
      toast({
        title: "Success",
        description: "Source updated successfully",
        duration: 5000,
      });
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Tooltip content={editItem ? "Update Source" : "Add Source"}>
        {isMobile ? (
          <NavItem
            icon={<PlusSquare className="w-5 h-5" />}
            label="Source"
            isActive={false}
            onClick={() => onOpen()}
          />
        ) : (
          <Button isIconOnly color="primary" size="sm" onPress={onOpen}>
            <PlusIcon size={20} />
          </Button>
        )}
      </Tooltip>
      <Modal
        backdrop="transparent"
        isOpen={isOpen}
        placement="bottom"
        shouldBlockScroll={false}
        size="5xl"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(_) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isLoggedIn
                  ? editItem
                    ? "Edit Source"
                    : "Add Source"
                  : "Login to Add Source"}
              </ModalHeader>
              <ModalBody>
                {isLoggedIn ? (
                  <Tabs fullWidth>
                    <Tab title="Live Stream">
                      <div className="max-h-60 overflow-y-scroll w-full">
                        {sources.map((source, index) => (
                          <div
                            key={index}
                            className="flex w-full max-sm:flex-col max-sm:gap-4 max-sm:mb-4 justify-center items-center mb-2 gap-2"
                          >
                            <Input
                              className="w-full min-w-[180px] h-[40px] "
                              classNames={{
                                label: "text-[11px]",
                              }}
                              endContent={<Kbd keys={["enter"]} />}
                              label="URL"
                              disabled={!!editItem}
                              size="sm"
                              value={source.url}
                              onChange={(e) => {
                                const newSources = [...sources];

                                newSources[index].url = e.target.value;
                                setSources(newSources);
                              }}
                              onKeyUp={(v) => {
                                if (v.key === "Enter") {
                                  addNewSource();
                                }
                                if (
                                  v.key === "Backspace" &&
                                  source.url!.length === 0 &&
                                  sources.length > 1
                                ) {
                                  setSources(
                                    sources.filter((_, i) => i !== index)
                                  );
                                }
                              }}
                            />
                            <Input
                              className="w-full h-[40px]"
                              classNames={{
                                label: "text-[11px]",
                              }}
                              endContent={
                                <Kbd
                                  keys={["enter"]}
                                  title="Enter to new source"
                                />
                              }
                              label="Name"
                              size="sm"
                              value={source.name}
                              onChange={(e) => {
                                const newSources = [...sources];

                                newSources[index].name = e.target.value;
                                setSources(newSources);
                              }}
                              onKeyUp={(v) => {
                                if (v.code === "Enter") {
                                  addNewSource();
                                }

                                if (
                                  v.key === "Backspace" &&
                                  source.url!.length === 0 &&
                                  sources.length > 1
                                ) {
                                  setSources(
                                    sources.filter((_, i) => i !== index)
                                  );
                                }
                              }}
                            />

                            {/* <Dropdown>
                          <DropdownTrigger>
                            <Button
                              color="default"
                              size="md"
                              className=" max-h-[40px] max-md:w-full"
                            >
                              {languages.find((v) => v.code === source.language)
                                ? languages.find(
                                    (v) => v.code === source.language
                                  )?.language
                                : "English"}
                            </Button>
                          </DropdownTrigger>

                          <DropdownMenu>
                            {languages.map((lang) => (
                              <DropdownItem
                                key={lang.code}
                                onClick={() => {
                                  const newSources = [...sources];

                                  newSources[index].language = lang.code;
                                  setSources(newSources);
                                }}
                              >
                                {lang.language}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown> */}

                            <LanguageSelector
                              onSelect={(lang) => {
                                const newSources = [...sources];

                                newSources[index].language = lang;
                                setSources(newSources);
                              }}
                            />

                            {index === sources.length - 1 && (
                              <Tooltip
                                content={
                                  editItem ? "Edit Source" : "Add Source"
                                }
                              >
                                <Button
                                  isIconOnly
                                  className=" max-h-[40px] max-sm:hidden"
                                  color="primary"
                                  isLoading={isLoading}
                                  size="md"
                                  onClick={() =>
                                    editItem ? updateSrouce() : createSources()
                                  }
                                >
                                  {editItem ? <Edit /> : <Plus />}
                                </Button>
                              </Tooltip>
                            )}

                            {index === sources.length - 1 && (
                              <div className="flex justify-end w-full md:hidden">
                                <Tooltip
                                  content={
                                    editItem ? "Edit Source" : "Add Source"
                                  }
                                >
                                  <Button
                                    // startContent={
                                    //   editItem ? "Edit Source" : "Add Source"
                                    // }
                                    className=" max-h-[40px] md:hidden mt-3"
                                    color="primary"
                                    isLoading={isLoading}
                                    startContent={<PlusIcon />}
                                    size="md"
                                    onClick={() =>
                                      editItem
                                        ? updateSrouce()
                                        : createSources()
                                    }
                                  >
                                    {editItem ? "Edit Source" : "Add Source"}
                                  </Button>
                                </Tooltip>
                              </div>
                            )}

                            {/* {index === sources.length - 1 && (
              <Button
                isIconOnly
                
                size="sm"
                onClick={addNewSource}
              >
                <CopyPlus size={18} />
              </Button>
            )} */}
                          </div>
                        ))}
                      </div>
                    </Tab>
                    {!editItem && (
                      <Tab title="Video/Audio File">
                        <div className="flex w-full max-sm:flex-col max-sm:gap-4 max-sm:mb-4 justify-center items-center mb-2 gap-2">
                          <Input
                            className="w-full h-[40px]"
                            classNames={{
                              label: "text-[11px]",
                            }}
                            label="Name"
                            size="sm"
                            value={uploadFileName || ""}
                            onChange={(e) => {
                              setUploadFileName(e.target.value);
                            }}
                          />

                          <LanguageSelector
                            onSelect={(lang) => {
                              setUploadFileLang(lang);
                            }}
                          />

                          <Button
                            startContent={<Upload />}
                            color="primary"
                            className=" min-w-[150px]"
                          >
                            {" "}
                            Upload{" "}
                          </Button>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                ) : (
                  <div className="flex justify-center items-center gap-2">
                    {/* <div className="flex gap-2 p-2">
                      <Button
                        color="primary"
                        className=" w-32"
                        size="md"
                        onClick={() => authProvider.login()}
                      >
                        Login
                      </Button>
                      <Button
                        color="primary"
                        className=" w-32"
                        size="md"
                        onClick={() => authProvider.register()}
                      >
                        Sign Up
                      </Button>
                    </div> */}
                  </div>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
