import { useState, useEffect } from "react";

import { Check, CreditCard, Minus, Plus } from "lucide-react";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import { Accordion, AccordionItem } from "@nextui-org/accordion";

import { Progress } from "@nextui-org/progress";
const plans = {
  free: {
    name: "Free",
    price: 0,
    description: "Basic access for registered users",
    features: [
      "Very limited sources (removed after 30 minutes)",
      "Basic transcription and subtitle generation",
      "Limited language support (English only)",
      "Watermarked outputs",
      "Usage dashboard for monitoring limits",
    ],
    baseAddons: {
      minutes: 5,
      languages: 1,
      sources: 1,
    },
  },
  pro: {
    name: "Pro",
    price: 49,
    description: "Full access for professional users",
    features: [
      "Multiple sources without daily limits",
      "All languages for transcription and translation",
      "Clean outputs without watermarks",
      "Batch processing (up to 10 files)",
      "Multiple subtitle formats (SRT, VTT, ASS)",
      "Basic API integration (100 requests/day)",
      "Third-party integrations",
      "Detailed usage statistics dashboard",
      "Option to purchase additional resources",
    ],
    baseAddons: {
      minutes: 100,
      languages: 20,
      sources: 5,
    },
  },
  enterprise: {
    name: "Enterprise",
    price: 99,
    description: "Advanced access for large organizations",
    features: [
      "All Pro features",
      "Higher API rate limits and dedicated endpoints",
      "Dedicated support and account management",
      "Custom integrations and feature requests",
      "Enhanced security features (e.g., single sign-on)",
      "Priority processing and higher batch limits",
      "Customizable usage plans",
    ],
    baseAddons: {
      minutes: 500,
      languages: 50,
      sources: 20,
    },
  },
};

const addonPrices = {
  minutes: 0.1, // per minute
  languages: 2, // per language
  sources: 5, // per source
};

interface PricingManagementProps {
  onNext: () => void;
}

export default function PricingManagement({ onNext }: PricingManagementProps) {
  const [currentPlan, setCurrentPlan] = useState("pro");
  const [selectedPlan, setSelectedPlan] = useState("pro");
  const [addons, setAddons] = useState(plans.pro.baseAddons);

  const [maxAddons, setMaxaddons] = useState({
    minutes: 1000,
    languages: 50,
    sources: 20,
  });

  useEffect(() => {
    setAddons(plans[selectedPlan as keyof typeof plans].baseAddons);
  }, [selectedPlan]);

  const handleAddonChange = (type: keyof typeof addons, increment: boolean) => {
    setAddons((prev) => {
      const newValue = increment ? prev[type] + 1 : prev[type] - 1;
      const minValue =
        plans[selectedPlan as keyof typeof plans].baseAddons[type];
      if (newValue < minValue || newValue > maxAddons[type]) return prev;
      return { ...prev, [type]: newValue };
    });
  };

  const calculateTotalPrice = () => {
    const planPrice = plans[selectedPlan as keyof typeof plans].price;
    const baseAddons = plans[selectedPlan as keyof typeof plans].baseAddons;
    const addonsPrice =
      (addons.minutes - baseAddons.minutes) * addonPrices.minutes +
      (addons.languages - baseAddons.languages) * addonPrices.languages +
      (addons.sources - baseAddons.sources) * addonPrices.sources;
    return planPrice + addonsPrice;
  };

  const renderProgressBar = (type: keyof typeof addons) => {
    const percentage = (addons[type] / maxAddons[type]) * 100;
    return <Progress value={percentage} className="h-2" />;
  };

  return (
    <div className="w-full max-w-5xl mx-auto space-y-6  py-4 pb-24">
      <Card isBlurred>
        <CardHeader className=" flex flex-col gap-2 items-start">
          <h1 className=" text-2xl font-semibold">Premium Plans</h1>
        </CardHeader>
        <CardBody>
          <h3 className="text-lg font-semibold mb-4">
            Current Plan: {plans[currentPlan as keyof typeof plans].name}
          </h3>
          <div className="grid gap-6 md:grid-cols-3">
            {Object.entries(plans).map(([key, plan]) => (
              <Card
                isPressable
                onClick={() => {
                  setCurrentPlan(key);
                  setSelectedPlan(key);
                }}
                key={key}
                className={`${selectedPlan === key ? "border-primary" : ""}`}
                classNames={{
                  base: "border-2 border-transparent p-0",
                  // data: {
                  //     selected: "border-primary",
                  //   },
                }}
              >
                <CardHeader className=" flex gap-2">
                  <h2>{plan.name}</h2>
                  <p className=" text-xl font-semibold ">${plan.price} / mo</p>
                </CardHeader>
                <CardBody>
                  <p className="text-sm mb-4">{plan.description}</p>
                  <div className="w-full flex items-center"></div>
                </CardBody>
                <CardFooter>
                  <Accordion className="w-full">
                    <AccordionItem title="View Features" value="features">
                      <ul className="list-none pl-0 space-y-1">
                        {plan.features.map((feature, index) => (
                          <li key={index} className="flex items-start">
                            <Check className="h-4 w-4 mr-2 mt-1 flex-shrink-0 text-primary" />
                            <span className="text-sm">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </AccordionItem>
                  </Accordion>
                </CardFooter>
              </Card>
            ))}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h1>Add-ons</h1>
          <p>Customize your plan with additional resources</p>
        </CardHeader>
        <CardBody className="space-y-6">
          {Object.entries(addons).map(([key, value]) => (
            <div key={key} className="space-y-2">
              <div className="flex items-center justify-between">
                <label htmlFor={key} className="w-24">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <div className="text-sm text-muted-foreground">
                  {value} / {maxAddons[key as keyof typeof maxAddons]}
                </div>
              </div>
              {renderProgressBar(key as keyof typeof addons)}
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Button
                    isIconOnly
                    variant="bordered"
                    onClick={() =>
                      handleAddonChange(key as keyof typeof addons, false)
                    }
                    disabled={
                      value <=
                      plans[selectedPlan as keyof typeof plans].baseAddons[
                        key as keyof typeof addons
                      ]
                    }
                  >
                    <Minus className="h-4 w-4" />
                  </Button>
                  <Input
                    id={key}
                    type="number"
                    onChange={(e) => {
                      setAddons({
                        ...addons,
                        [key]: parseInt(e.target.value),
                      });

                      if (
                        parseInt(e.target.value) <=
                        maxAddons[key as keyof typeof maxAddons]
                      )
                        return;

                      setMaxaddons({
                        ...maxAddons,
                        [key]: parseInt(e.target.value),
                      });
                    }}
                    value={value as any} 
                    className="w-20 text-center"
                  />
                  <Button
                    isIconOnly
                    variant="bordered"
                    onClick={() =>
                      handleAddonChange(key as keyof typeof addons, true)
                    }
                    disabled={value >= maxAddons[key as keyof typeof maxAddons]}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
                <div className="w-24 text-right">
                  $
                  {(
                    (value -
                      plans[selectedPlan as keyof typeof plans].baseAddons[
                        key as keyof typeof addons
                      ]) *
                    addonPrices[key as keyof typeof addonPrices]
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          ))}
        </CardBody>
        <CardFooter className=" flex justify-between items-center mt-6">
          <div className="text-2xl max-lg:text-lg font-bold">
            Total: ${calculateTotalPrice().toFixed(2)}/mo
          </div>
          <Button
            color="primary"
            startContent={<CreditCard />}
            className=" max-md:w-[150px] lg:w-[200px]"
            size="md"
            onClick={onNext}
          >
            Upgrade
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}


