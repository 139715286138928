import { useState } from "react";

import {  Clock, Globe } from "lucide-react";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Radio, RadioGroup, RadioProps } from "@nextui-org/radio";
import { Button } from "@nextui-org/button";
import { cn } from "@nextui-org/theme";
import { Divider } from "@nextui-org/divider";

interface PaymentProcessingProps {
  onNext: () => void;
}

export default function PaymentProcessing({ onNext }: PaymentProcessingProps) {
  const [paymentMethod, setPaymentMethod] = useState("paypal");

  // This would typically come from your application state or API
  const purchaseDetails = {
    plan: "Pro",
    billingCycle: "Monthly",
    price: 29.99,
    additionalCredits: 50,
    additionalCost: 50,
    totalCost: 79.99,
    newSources: 7,
    newLanguages: 12,
    newMinutes: 150,
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Payment Processing</h1>

      <div className="grid md:grid-cols-2 gap-8">
        <Card isBlurred>
          <CardHeader>
            <h1>Purchase Summary</h1>
            <p>Review your purchase details</p>
          </CardHeader>
          <CardBody className="space-y-4">
            <div className="flex justify-between">
              <span>Plan:</span>
              <span className="font-semibold">
                {purchaseDetails.plan} ({purchaseDetails.billingCycle})
              </span>
            </div>
            <div className="flex justify-between">
              <span>Plan Price:</span>
              <span className="font-semibold">
                ${purchaseDetails.price.toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between">
              <span>Additional Credits:</span>
              <span className="font-semibold">
                {purchaseDetails.additionalCredits}
              </span>
            </div>
            <div className="flex justify-between">
              <span>Additional Cost:</span>
              <span className="font-semibold">
                ${purchaseDetails.additionalCost.toFixed(2)}
              </span>
            </div>
            <Divider />
            <div className="flex justify-between text-lg font-bold">
              <span>Total Cost:</span>
              <span>${purchaseDetails.totalCost.toFixed(2)}</span>
            </div>
            <Divider />
            <div className="space-y-2">
              <h3 className="font-semibold">New Updates:</h3>
              <div className="flex items-center space-x-2">
                <Globe className="h-4 w-4" />
                <span>{purchaseDetails.newSources} Sources</span>
              </div>
              <div className="flex items-center space-x-2">
                <Globe className="h-4 w-4" />
                <span>{purchaseDetails.newLanguages} Languages</span>
              </div>
              <div className="flex items-center space-x-2">
                <Clock className="h-4 w-4" />
                <span>{purchaseDetails.newMinutes} Minutes</span>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card isBlurred>
          <CardHeader>
            <h1>Select Payment Method</h1>
          </CardHeader>
          <CardBody>
            <RadioGroup
              value={paymentMethod}
              onValueChange={setPaymentMethod}
              className="flex"
              orientation="horizontal"
            >
              <CustomRadio value="paypal" id="paypal">
                <div className="flex items-center space-x-2 flex-1 rounded p-4">
                  <label
                    htmlFor="paypal"
                    className="flex items-center space-x-2 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.015-2.598 4.68-5.806 4.68h-2.19a1.08 1.08 0 0 0-1.065.912l-1.12 7.106c-.024.153-.043.306-.066.46h4.927c.524 0 .968-.382 1.05-.9l.438-2.777c.082-.518.526-.9 1.05-.9h1.33c4.017 0 7.146-1.63 8.058-6.343.392-2.023.169-3.743-.958-4.95z" />
                    </svg>
                    <span>PayPal</span>
                  </label>
                </div>
              </CustomRadio>
              <CustomRadio value="google-pay" id="google-pay">
                <div className="flex items-center space-x-2 flex-1  rounded p-4">
                  <label
                    htmlFor="google-pay"
                    className="flex items-center space-x-2 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" />
                      <path
                        d="M7.2 13.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5zm7.5 1.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm-6-6c-.828 0-1.5-.672-1.5-1.5S7.872 6 8.7 6s1.5.672 1.5 1.5S9.528 9 8.7 9zm6 0c-.828 0-1.5-.672-1.5-1.5S13.872 6 14.7 6s1.5.672 1.5 1.5S15.528 9 14.7 9z"
                        fill="#fff"
                      />
                    </svg>
                    <span>Google Pay</span>
                  </label>
                </div>
              </CustomRadio>
              <CustomRadio value="stripe" id="stripe">
                <div className="flex items-center space-x-2 flex-1 rounded p-4">
                  <label
                    htmlFor="stripe"
                    className="flex items-center space-x-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path d="M13.976 9.15c-2.172-.806-3.356-1.426-3.356-2.409 0-.831.683-1.305 1.901-1.305 2.227 0 4.515.858 6.09 1.631l.89-5.494C18.252.975 15.697 0 12.165 0 9.667 0 7.589.654 6.104 1.872 4.56 3.147 3.757 4.992 3.757 7.218c0 4.039 2.467 5.76 6.476 7.219 2.585.92 3.445 1.574 3.445 2.583 0 .98-.84 1.545-2.354 1.545-1.875 0-4.965-.921-6.99-2.109l-.9 5.555C5.175 22.99 8.385 24 11.714 24c2.641 0 4.843-.624 6.328-1.813 1.664-1.305 2.525-3.236 2.525-5.732 0-4.128-2.524-5.851-6.594-7.305h.003z" />
                    </svg>
                    <span>Stripe</span>
                  </label>
                </div>
              </CustomRadio>
            </RadioGroup>

            {paymentMethod === "paypal" && (
              <div className="mt-4">
                <p>
                  You will be redirected to PayPal to complete your payment.
                </p>
              </div>
            )}

            {paymentMethod === "google-pay" && (
              <div className="mt-4">
                <p>
                  You will be prompted to use Google Pay for this transaction.
                </p>
              </div>
            )}

            {paymentMethod === "stripe" && (
              <div className="mt-4">
                <p>
                  You will be redirected to Stripe to complete your payment
                  securely.
                </p>
              </div>
            )}
          </CardBody>
          <CardFooter>
            <Button
              onClick={onNext}
              variant="solid"
              color="primary"
              className="w-full"
            >
              Process Payment
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

const CustomRadio = (props: RadioProps) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between w-full",
          "flex-row-reverse max-w-[300px] cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Radio>
  );
};
