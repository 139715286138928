import { Button } from "@nextui-org/button";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Input } from "@nextui-org/input";

interface AccountSettingsProps {
  onUpgrade: () => void;
  onLogout: () => void;
  onInvoices: () => void;
}

export default function AccountSettings({
  onUpgrade,
  onLogout,
  onInvoices
}: AccountSettingsProps) {
  return (
    <div className="container max-w-6xl mx-auto px-4 pb-24">
      <h1 className="text-3xl font-bold mb-8">Account Settings</h1>
      <div className="space-y-8">
        <Card>
          <CardHeader>
            <h1>Personal Information</h1>
          
          </CardHeader>
          <CardBody className="space-y-4">
            <div>
              <label htmlFor="name">Name</label>
              <Input id="name" defaultValue="Test" />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <Input
              readOnly
              isDisabled
               id="email" type="email" defaultValue="test@gmail.com" />
            </div>
          </CardBody>
          <CardFooter>
            <Button>Save Changes</Button>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader className=" flex flex-col justify-start gap-2 items-start">
            <h1>Trial and Promotions</h1>
          
          </CardHeader>
          <CardBody className="space-y-4">
            <div>
              <p>
                <strong>Trial Status:</strong> 7 days remaining
              </p>
              <Button
                onClick={() => {
                  onUpgrade();
                }}
                color="primary"
                variant="solid"
                className="mt-2"
              >
                Upgrade to Pro
              </Button>
            </div>
            <div>
              <label htmlFor="promo-code">Promotional Code</label>
              <div className="flex space-x-2 mt-1">
                <Input id="promo-code" placeholder="Enter code" />
                <Button>Apply</Button>
              </div>
            </div>
          </CardBody>
        </Card>


        <Card>
          <CardHeader className=" flex flex-col justify-start gap-2 items-start">
            <h1>Payment Settings</h1>
          
          </CardHeader>
          <CardBody className="space-y-4">
            <div>
             
              <Button
                onClick={() => {
                  onInvoices();
                }}
                variant="bordered"
                className="mt-2"
              >
              Billing and Invoices
              </Button>
            </div>
            
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <h1> Logout Account</h1>
          </CardHeader>

          <CardFooter>
            <Button
              onClick={() => {
                onLogout();
              }}
              color="danger"
            >
              Logout Account
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
